.Selection{
    width: 100%;
    margin-top: 100px;
}

.selection-block{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap!important;
    margin-top: 80px;
}

.selection-block-card{
    width: 320px;
    margin: auto;
}

.selection-card-img{
    width: 100%;
    height: 300px;
}
