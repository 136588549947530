.swiper{
    min-height: 100vh;
    display: grid;
    place-content: center;
}

.slider{
    height: 500px;
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slide-truck{
    display: flex;
    width: 100%;
    /* align-items: center; */
    width: calc(250px * 18);
    animation: scroll 40s linear infinite;
}

@keyframes scroll {
    100% {
        transform: translateX(0);
    }

    0% {
        transform: translateX(calc(-250px * 9));
    }
}

.slide{
    height: 100%;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 5px;
    perspective: 100px;
    margin-right: 100px;
}

.swiper-img{
    width: 100%;
    height: 100%;
}

.slider::before, 
.slider::after{
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,
    rgba(255,255,255,0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
}

.slider::before{
    top: 0;
    left: 0;
}

.slider::after{
    top: 0;
    right: 0;
    transform: rotateZ(180deg);
}





/* .swiper{
    min-height: 100vh;
    display: grid;
    place-content: center;
} */
