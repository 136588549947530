.course {
    width: 100%;
    margin-top: 100px;
}

.course-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.course-card {
    width: 32%;
    height: 500px;
    margin-top: 80px;
    background-color: #F7F7F7;
    border-radius: 40px;
    position: relative;
}

.course-card-img {
    width: 100%;
    height: 45%;
    padding: 0;
    margin: 0;
}

.blocck{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: space-between;
}

.card-intensive {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}


.intensive-title {
    font-size: 24px;
    line-height: 29px;
    color: #333333;
}

.intensive-month {
    font-size: 10px;
    background-color: #61ABA7;
    padding: 12px 18px;
    color: white;
    border-radius: 30px;
}

.course-card-desc {
    padding: 15px 20px;
}

.course-card-reg{
    position: absolute;
    left: 0;
    bottom:0;
    padding: 10px 20px;
    margin-bottom: 20px;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.course-card-btn {
    color: white;
    background: linear-gradient(#5FACA9 100% #73C0BD 100% #5FACA9 100%);
    background-color: #418E8B;
    border: none;
    font-size: 9px;
    padding: 20px 30px;
    border-radius: 30px; 
}

.card-sum{
    color: #4B9895;
    font-size: 14px;
    line-height: 21px;
    margin-left: 60px;
}

@media only screen and (max-width: 992px){
    .course-block {
        width: 100%;
    }
    
    .course-card {
        width: 32%;
        height: 500px;
        margin-top: 80px;
        background-color: #F7F7F7;
        border-radius: 40px;
        position: relative;
    }
    
    .course-card-img {
        width: 100%;
        height: 45%;
        padding: 0;
        margin: 0;
    }
    
    .blocck{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .card-intensive {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
    }
    
    
    .intensive-title {
        font-size: 18px;
        line-height: 22px;
    }
    
    .intensive-month {
        font-size: 10px;
        background-color: #61ABA7;
        padding: 8px 12px;
    }
    
    .course-card-desc {
        padding: 15px 20px;
        font-size: 14px!important;
    }
    
    .course-card-reg{
        padding: 8px 15px;
        margin-bottom: 20px;
    }
    
    .course-card-btn {
        font-size: 8px;
        padding: 10px 20px;
    }
    
    .card-sum{
        color: #4B9895;
        font-size: 10px;
        line-height: 16px;
        margin-left: 10px;
    }
    
}

@media only screen and (max-width: 780px){

    .course-block {
        width: 100%;
        flex-direction: column;
    }

    .course-card {
        width: 100%;
        /* height: ; */
        margin-top: 80px;
        background-color: #F7F7F7;
        border-radius: 40px;
        position: relative;
    }
    
    .course-card-img {
        width: 100%;
        height: 60%;
        padding: 0;
        margin: 0;
    }
    
    .blocck{
        display: flex;
        flex-direction: column;
        /* align-items: flex-start; */
        justify-content: space-between;
    }
    
    .card-intensive {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
    }
    
    
    
    
}