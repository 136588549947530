.questions {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 50px;
    /* text-align: center; */
}

.accordion-block {
    margin-top: 70px;
}


.accordion {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 12px;
}

.accordion-active {
    box-shadow: 0 0 12px 1px #a3bda6;
    border-radius: 10px;
}

.accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    padding: 20px 30px;
    cursor: pointer;
    background-color: #D7F8DB;
    border-radius: 10px;
    /* margin-bottom: 12px; */
    color: #333333;

}

/* .accordion:first-child{
    border: 1px solid red;
} */

.accordion-title-active {
    border-radius: 10px 10px 0 0;
}

.accordion-title span {
    display: inline-flex;
    width: 40px;
    height: 40px;
    background-color: #5FACA9;
    border-radius: 50%;
    transform: rotate(0);
    transition: transform 0.2s ease-out;
}

.accordion-title-active span {
    transform: rotate(45deg);
}

.accordion-title span::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 2px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 50%;
}

.accordion-title span::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 50%;
}

.accordion-content {
    font-size: 16px;
    overflow: hidden;
    padding: 5px 20px;
    box-sizing: content-box;
    background-color: #D7F8DB;
    padding-bottom: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    padding-top: 5px;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: max-height 0.2s ease-out, padding 0.2s ease-out, opacity 0.1s ease-out 0.2s, visibility 0.1s ease-out 0.2s;
}

.accordion-content-active {
    visibility: visible;
    opacity: 1;
    padding: 20px;
    padding-left: 30px;
    transition: max-height 0.2s ease-out, padding 0.2s ease-out, opacity 0.1s ease-out 0.2s, visibility 0.1s ease-out 0.2s;
}

.accord-title {
    width: 80%;
    font-size: 18px;
}

.phone {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #d7f8dba3;
    padding: 40px 50px;
    margin-top: 80px;
    border-radius: 10px;
    height: fit-content;
}

.phone-left {
    width: 300px;
}

.phone-title {
    font-size: 40px;
    line-height: 48px;
    color: #333333;
    padding: 20px 0;
}

.phone-desc {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
}

.phone-right {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: space-around;
    /* height: 100%; */
}

.phone-name {
    padding: 20px;
    border: none;
    border-radius: 30px;
    margin-bottom: 16px;
    background-color: #BFF1C5;
}

.phone-btn {
    padding: 20px;
    border: none;
    border-radius: 30px;
    background: linear-gradient(#5FACA9 100% #73C0BD 100% #5FACA9 100%);
    background-color: #418E8B;
    color: white;
}

.modal-form{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.708);
    backdrop-filter: blur(5px);
    display: none;
    transition: 0.3s ease-in-out;
}

.modal-form-open{
    display: flex;
    z-index: 999;
}

.phone-right2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 300px;
    /* height: 100%; */
    margin: auto;
    background-color: rgb(189, 186, 186);
    padding: 20px;
    border-radius: 10px;
}

.modal-form-title{
    text-align: center;
    font-size: 18px;
    color: #333333;
    padding: 15px;
}

@media only screen and (max-width: 770px){
    .accordion{
        width: 100%!important;
    }



    .phone {
        flex-direction: column;
    }
    
    .phone-left{
        width: 280px;
    }
    
    .phone-title {
        font-size: 24px;
        line-height: 30px;
        padding: 15px 0;
        text-align: center;
    }
    
    .phone-desc {
        text-align: center;
    }
    
    .phone-right {
        width: 280px;
    }
}

@media only screen and (max-width: 500px){
    .accordion-title {
        font-size: 14px;
    }

    .accord-title{
        font-size: 12px;
    }
}