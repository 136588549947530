.adven{
    width: 98%;
    padding: 20px;
    margin: auto;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.adven-title{
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 36px;
    color: #333333;
    line-height: 42px;
}

.adven-block-top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
    margin: auto;
}

.adven-card{
    width: 270px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.adven-card-img{
    width: 150px;
    height: 150px;
    padding: 20px;
    background-color: #d7f8dbf4;
    border-radius: 50%;
}

.adven-card-span{
    width: 100%;
    text-align: center;
    padding: 20px;
    font-size: 18px;
    padding-top: 0;
    line-height: 23px;
    color: #494747;
}

.child{
    color: #5beae3;
}

.adven.btn{
    text-align: center;
    margin: auto;
}

.add-btn{
    width: 300px;
    padding: 30px 35px;
    background-color:#418E8B;
    border: none;
    text-align: center;
    border-radius: 50px;
    color: white;
    margin-top: 40px;
    cursor: pointer;
    /* margin: auto; */
}

@media only screen and (max-width: 1000px){

    .adven{
        width: 100%;
    }
    
    .adven-card{
        width: 200px;
    }
    
    .adven-card-img{
        width: 80px;
        height: 80px;
    }
    
    .adven-card-span{
        font-size: 14px;
        line-height: 18px;
    }
    
}

@media only screen and (max-width: 468px){

    .adven-title{
        font-size: 20px;
        color: #333333;
        line-height: 26px;
    }

    .adven-block-top{
        align-items: center;
        justify-content: center;
    }
    .adven-card{
        width: 300px;
    }

    .adven-card-img{
        width: 150px;
        height: 150px;
    }

    .adven-card-span{
        font-size: 16px;
        line-height: 23px;
    }

    .adven.btn{
        text-align: center;
        margin: auto;
    }
    
    .add-btn {
        width: 200px;
        padding: 20px 25px;
        border-radius: 50px;
        margin-top: 40px;
    }
}