.navbar{
    display: flex;
    align-items: center!important;
    justify-content: space-between;
    padding: 20px 0;
}

.logo-block{
    width: 300px;
    display: flex;
    align-items: center;
    padding:5px; 
}

.logo-desc{
    font-size: 12px;
    padding:5px;
    color: #000000;
}

.nav-list{
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-between;
    width: 40%;
}

.nav-link{
    text-decoration: none;
    color: #333333;
    padding: 5px 10px;
}

.nav-link:hover{
    background-color: #e6e1e1;
    border-radius: 5px;
}

.nav-call{
    margin: 0;
    text-decoration: none;
    color: #333333;
    font-size: 20px;
}

.toggle{
    width: 40px;
    height: 40px;
    /* background-color: red; */
    padding: 5px;
}

.toggle-line{
    width: 40px;
    height: 5px;
    background-color: black;
}

@media only screen and (max-width: 1064px){
    .nav-list{
        display: none!important;
    }
}

@media only screen and (max-width: 750px){
    .logo-desc{
        display: none;
    }

    .nav-call{
        font-size: 16px;
    }

    .logo-block{
        width: fit-content;
    }
}

@media only screen and (max-width: 450px){
    .nav-call{
        font-size: 12px;
    }

    .cal-item{
        font-size: 14px;
    }

    .logo-block{
        width: 130px;
    }
    .logoLink{
        width: 100%;
    
    }

    .logo-image{
        width: 100%;
    }
}